'use client'

import ArrowForward from '@/app/_components/Icon/ArrowForward'
import TypeOff from '@/app/_components/Icon/TypeOff'
import TypeOn from '@/app/_components/Icon/TypeOn'
import ChampeInniePlayer from '@/app/_components/Shared/LottiePlayer/ChampeInniePlayer'
import { Button } from '@/app/_primitives/Button'
import { TextArea } from '@/app/_primitives/TextArea'
import { cn } from '@/app/utils'
import { ErrNotificationAPI } from '@/data/error/client'
import { ErrMessageSchema } from '@/data/error/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import Link from 'next/link'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

const reasons = [
  {
    code: 'loading',
    label: '페이지가 정상적으로 로딩되지 않아요.',
    message: '페이지가 정상적으로 로딩되지 않아요.',
  },
  {
    code: 'click',
    label: '버튼을 눌렀는데 반응이 없어요.',
    message: '버튼을 눌렀는데 반응이 없어요.',
  },
  {
    code: 'submit',
    label: '입력한 정보가 저장되지 않아요.',
    message: '입력한 정보가 저장되지 않아요.',
  },
  {
    code: 'unknown',
    label: '알 수 없는 오류 메시지가 떠요.',
    message: '알 수 없는 오류 메시지가 떠요.',
  },
  { code: 'etc', label: '기타 (직접 입력 가능)', message: '' },
]

type FormData = z.infer<typeof ErrMessageSchema.ErrMsgs>

export default function Error({
  error,
  // reset,
}: {
  error: Error & { digest?: string }
  // reset: () => void
}) {
  const [isSuccess, setIsSuccess] = useState(false)
  const { control, register, setValue, watch, handleSubmit } =
    useForm<FormData>({
      defaultValues: {
        id: '',
        message: '',
      },
      resolver: zodResolver(ErrMessageSchema.ErrMsgs),
    })
  const errorId = watch('id')

  const devMutation = ErrNotificationAPI.useSendDev()

  const mutation = ErrNotificationAPI.useSend({
    onSuccess() {
      setIsSuccess(true)
    },
  })

  useEffect(() => {
    if (error) {
      devMutation.mutate({
        error: error.stack ?? '',
        message: error.message,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const handleChannelTalk = () => {
    if (window.ChannelIO) {
      window.ChannelIO('showMessenger')
    } else {
      console.error('ChannelIO is not initialized')
    }
  }

  const handleSendErrMessage = (data: FormData) => {
    if (data.id === '') return

    let message = ''
    if (data.id === 'etc') {
      message = data.message
    } else {
      message = reasons.filter((r) => r.code === data.id).pop()?.message ?? ''
    }

    mutation.mutate({ ...data, message })
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center overflow-auto bg-french-bleu py-100">
      {!isSuccess && (
        <form
          className={'flex flex-col gap-40'}
          onSubmit={handleSubmit(handleSendErrMessage)}>
          <div
            className={
              'flex flex-col items-center justify-center gap-19 px-10 text-white'
            }>
            <ChampeInniePlayer />
            <p className={'c-h4'}>앗, 예상치 못한 오류가 발생했어요.</p>
            <p className={'c-b2'}>아래에서 시도하려던 작업을 선택해 주세요.</p>
          </div>
          <div className={'flex w-468 flex-col gap-12'}>
            <div className={'flex flex-col gap-8 '}>
              {reasons.map((reason, idx) => (
                <Controller
                  name={'id'}
                  key={`chk-${idx}`}
                  control={control}
                  render={({ field }) => (
                    <Option
                      {...field}
                      id={reason.code}
                      size={'lg'}
                      variant={'light'}
                      content={
                        <p className={'c-h7 text-white'}>{reason.label}</p>
                      }
                      checked={field.value === reason.code}
                      onChecked={(_) => {
                        setValue('id', reason.code)
                        if (reason.code !== 'etc') {
                          setValue('message', '')
                        }
                      }}
                    />
                  )}
                />
              ))}
              <TextArea
                disabled={errorId !== 'etc'}
                className={'bg-[#FFFFFFCC] placeholder:text-[#737373]'}
                {...register('message')}
                placeholder={
                  'ex. 버튼을 눌렀는데 계속 로딩만 되고 진행이 되지 않아요.'
                }
              />
            </div>
          </div>
          <Button
            type={'submit'}
            size={'lg'}
            loading={mutation.isPending}
            color={'butterScotch'}
            className={'w-full'}>
            오류 신고하기
          </Button>
          <div className={'c-h6 flex items-center justify-center'}>
            <p className={'text-[#80A8E7]'}>혹시 급한 문제라면?</p>&nbsp;
            <p
              className={'cursor-pointer text-butterscotch underline'}
              onClick={handleChannelTalk}>
              고객센터
            </p>
          </div>
        </form>
      )}
      {isSuccess && (
        <div className={'flex flex-col gap-80'}>
          <div className={'flex flex-col gap-19 text-white'}>
            <p className={'c-h4'}>신고해 주셔서 감사합니다!</p>
            <p className={'c-b2 text-center'}>
              보내주신 내용을 확인하고 <br /> 더 나은 서비스를 위해
              개선하겠습니다.
            </p>
          </div>
          <div className={'flex flex-col items-center justify-center gap-40'}>
            <Button
              type="button"
              asChild
              className={'w-258'}
              color={'butterScotch'}>
              <Link href={'/'}>
                홈으로 돌아가기 <ArrowForward className={'size-16'} />
              </Link>
            </Button>
            <div className={'c-h6 flex items-center justify-center'}>
              <p className={'text-[#80A8E7]'}>혹시 급한 문제라면?</p>&nbsp;
              <p
                className={'cursor-pointer text-butterscotch underline'}
                onClick={handleChannelTalk}>
                고객센터
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function Option({
  id,
  content,
  checked,
  size,
  variant,
  onChecked,
  className,
}: {
  id: string
  content: React.ReactNode
  checked: boolean
  size: 'lg' | 'sm'
  variant: 'light' | 'dark'
  onChecked: (_: boolean) => unknown
  className?: string
}) {
  const handleClick = useCallback(() => {
    onChecked(!checked)
  }, [onChecked, checked])
  return (
    <div className={cn('flex items-center gap-4 px-12 py-8', className)}>
      <button type={'button'} id={id} onClick={handleClick}>
        {checked ? (
          <TypeOn
            className={cn('h-20 w-20', {
              'h-16 w-16': size === 'sm',
              'fill-black': variant === 'dark',
            })}
          />
        ) : (
          <TypeOff
            className={cn('h-20 w-20', {
              'h-16 w-16': size === 'sm',
            })}
          />
        )}
      </button>
      <input
        type="checkbox"
        aria-hidden="true"
        className={`pointer-events-none absolute m-0 size-25 -translate-x-full opacity-0`}
        tabIndex={-1}
        checked={checked}
        onChange={(e) => onChecked(e.target.checked)}
      />
      <label className="c-b3 cursor-pointer" htmlFor={id}>
        {content}
      </label>
    </div>
  )
}
