import { SvgIconProps } from '@/types/helpers'

export default function TypeOn(props: SvgIconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="12" cy="12" r="11" stroke="#FDCC53" stroke-width="2" />
      <circle cx="12" cy="12" r="7" fill="#FDCC53" />
    </svg>
  )
}
