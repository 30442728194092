import { z } from 'zod'

const ErrMsgs = z
  .object({
    id: z.string(),
    message: z.string().max(200),
  })
  .superRefine(({ id, message }, ctx) => {
    if (id === 'etc' && message.length === 0) {
      ctx.addIssue({
        code: 'custom',
        path: ['message'],
      })
    }
  })

const DevErrMsgs = z.object({
  error: z.string(),
  message: z.string(),
})

export const ErrMessageSchema = {
  ErrMsgs,
  DevErrMsgs,
}
