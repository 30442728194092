import { SvgIconProps } from '@/types/helpers'

export default function TypeOff(props: SvgIconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle
        cx="12"
        cy="12"
        r="11"
        stroke="#D9D9D9"
        stroke-opacity="0.4"
        stroke-width="2"
      />
    </svg>
  )
}
