import { ErrMessageSchema } from '@/data/error/schema'
import { api } from '@/trpc/client'
import { createMutation } from 'react-query-kit'
import { z } from 'zod'

const useSend = createMutation({
  mutationFn: async (variables: z.infer<typeof ErrMessageSchema.ErrMsgs>) => {
    return api.error.sendToSlack.mutate({ message: variables.message })
  },
})

const useSendDev = createMutation({
  mutationFn: async (
    variables: z.infer<typeof ErrMessageSchema.DevErrMsgs>,
  ) => {
    return api.error.sendDevError.mutate(variables)
  },
})

export const ErrNotificationAPI = {
  useSend,
  useSendDev,
}
